import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SessionService, UserService, VehiclesService, BrandsService } from '../../services';
import { Sidebar, FormInput, DropDown, Switch, FormTextArea } from '../../components';
import { format } from 'date-fns';

const VehicleEdit = () => {
  const navigate = useNavigate();
  const { vehicleId } = useParams();

  const [users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);

  const [brand, setBrand] = useState('');
  const [variant, setVariant] = useState('');
  const [model, setModel] = useState('');
  const [drivetrain, setDrivetrain] = useState('');
  const [year, setYear] = useState(0);
  const [transmission, setTransmission] = useState('');
  const [power, setPower] = useState(0);
  const [weight, setWeight] = useState(0);
  const [isDefault, setIsDefault] = useState(false);
  const [user, setUser] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [mods, setMods] = useState('');
  const [photo, setPhoto] = useState('');

  useEffect(() => {
    const getUsers = async () => {
      const data = await UserService.getAllUsers(0, 1000);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        const usersList = [];
        for (const user of data.results) {
          usersList.push({
            key: user._id,
            value: `${user.name} (${user.email})`,
          });
        }

        setUsers(() => usersList);
      }
    };

    const getBrands = async brand => {
      const data = await BrandsService.getAllBrands(0, 1000);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        const brandsList = [];
        const modelsList = [];

        for (const b of data.results) {
          brandsList.push({
            key: b._id,
            value: b.name,
            models: b.models,
          });

          if (b._id === brand) {
            for (const m of b.models) {
              modelsList.push({
                key: m._id,
                value: m.name,
                variants: m?.variants,
              });
            }
          }
        }

        setBrands(() => brandsList.sort((a, b) => a.value.localeCompare(b.value)));
        setModels(() => modelsList.sort((a, b) => a.value.localeCompare(b.value)));
      }
    };

    const getData = async () => {
      const data = await VehiclesService.getVehicle(vehicleId);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.errorCode === 13) {
        SessionService.clearSession();
      }

      if (data.results) {
        setBrand(() => data.results?.brand?._id);
        setModel(() => data.results?.model?._id);
        setVariant(() => data.results?.variant);
        setDrivetrain(() => data.results?.drivetrain);
        setYear(() => data.results?.year);
        setTransmission(() => data.results?.transmission);
        setPower(() => data.results?.power);
        setWeight(() => data.results?.weight);
        setIsDefault(() => data.results?.isDefault);
        setUser(() => data.results?.user);
        setCreatedAt(() => data.results?.createdAt);
        setUpdatedAt(() => data.results?.updatedAt);
        setMods(() => data.results?.mods);
        setPhoto(() => data.results?.photo);
        getBrands(data.results?.brand?._id);
      }
    };

    getData();
    getUsers();
  }, [vehicleId]);

  useEffect(() => {
    const variantsList = [];

    if (models) {
      for (const m of models) {
        if (m.key === model) {
          if (model?.variants) {
            for (const v of m.variants) {
              variantsList.push({
                key: v._id,
                value: v.name,
              });
            }
          }
        }
      }

      setVariants(() => variantsList);
    }
  }, [models, model]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!brand || !model || !drivetrain || !year || !power) {
      alert('Please fill all the required fields');
      return;
    }

    if (year <= 0 || power <= 0) {
      alert('Year and power must be greater than 0');
      return;
    }

    const vehicle = {
      brand: brand,
      model: model,
      variant: variant,
      drivetrain,
      year,
      transmission,
      power,
      weight,
      isDefault,
      user: user,
      mods,
    };

    const data = await VehiclesService.updateVehicle(vehicleId, vehicle);

    if (data.results) {
      alert('Updated successfully');
    } else {
      alert('Something went wrong');
    }
  };

  const handleDelete = async e => {
    e.preventDefault();

    if (window.confirm(`Delete ${vehicleId} vehicle?`)) {
      await VehiclesService.deleteVehicle(vehicleId);

      alert('Removed successfully');
      navigate('/vehicles');
    }
  };

  const handleBrandChange = async text => {
    const brand = brands.find(b => b.key === text);
    const modelsList = [];
    setBrand(brand.key);

    for (const m of brand.models) {
      modelsList.push({
        key: m._id,
        value: m.name,
        variants: m?.variants,
      });
    }

    setModels(() => modelsList);
  };

  const handleModelChange = async text => {
    const model = models.find(m => m.key === text);
    const variantsList = [];
    setModel(model.key);

    if (model?.variants) {
      for (const v of model?.variants) {
        variantsList.push({
          key: v._id,
          value: v.name,
        });
      }
    }

    setVariants(() => variantsList);
    setVariant(() => '');
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white rounded-lg">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        {vehicleId && (
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <h1 className="flex flex-row mb-8 text-2xl">
                  <Link to="/vehicles">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 mt-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                  </Link>

                  <span className="mt-1">{vehicleId}</span>
                </h1>

                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="form-section">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">General</h2>

                      {photo && <img src={photo} alt="placeholder" className="object-contain w-full mb-4 rounded-md" />}

                      <DropDown
                        label="Driver"
                        placeholder="Please choose a driver"
                        values={users}
                        value={user}
                        required={true}
                        onChange={text => setUser(text)}
                      />
                      <DropDown
                        label="Brand"
                        placeholder="Please choose a brand"
                        values={brands}
                        value={brand}
                        required={true}
                        onChange={text => handleBrandChange(text)}
                      />
                      <DropDown
                        label="Model"
                        placeholder="Please choose a model"
                        values={models}
                        value={model || ''}
                        required={true}
                        onChange={text => handleModelChange(text)}
                      />
                      <FormInput
                        label="Variant"
                        value={variant}
                        onChange={text => setVariant(text)}
                      />
                      <DropDown
                        label="Drivetrain"
                        placeholder="Please choose a drivetrain"
                        values={[
                          {
                            key: 'RWD',
                            value: 'RWD',
                          },
                          {
                            key: 'FWD',
                            value: 'FWD',
                          },
                          {
                            key: 'AWD',
                            value: 'AWD',
                          },
                        ]}
                        value={drivetrain}
                        required={true}
                        onChange={text => setDrivetrain(text)}
                      />
                      <FormInput
                        label="Year"
                        type="number"
                        step="1"
                        placeholder="e.g. 2020"
                        value={year}
                        required={true}
                        onChange={text => setYear(text)}
                      />
                      <FormInput
                        label="Power"
                        type="number"
                        step="1"
                        placeholder="e.g. 450"
                        value={power}
                        required={true}
                        onChange={text => setPower(text)}
                      />
                      <DropDown
                        label="Transmission"
                        placeholder="Please choose a transmission"
                        values={[
                          {
                            key: 'MT',
                            value: 'MT',
                          },
                          {
                            key: 'AT',
                            value: 'AT',
                          },
                        ]}
                        value={transmission}
                        onChange={text => setTransmission(text)}
                      />
                      <FormInput label="Weight" type="number" placeholder="e.g. 1500" value={weight} onChange={text => setWeight(text)} />
                      <FormTextArea
                        label="Mods"
                        type="text"
                        placeholder="e.g. Brakes, Engine..."
                        value={mods}
                        onChange={text => setMods(text)}
                      />
                      <Switch title="Is Default?" value={isDefault} onChange={selected => setIsDefault(selected)} />

                      <div className="mt-4">
                        <h3 className="text-xs">Created at {createdAt && format(new Date(createdAt), 'dd/MM/yyyy')}</h3>
                        <h3 className="text-xs">Updated at {updatedAt && format(new Date(updatedAt), 'dd/MM/yyyy')}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <input type="submit" value="Save" className="btn min-w-[120px]" />
                    <button type="button" className="btn-delete min-w-[120px] ml-4" onClick={handleDelete}>
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default VehicleEdit;
